<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("newAssets.addNewAsset") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="row">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> {{ $t("addNewContactsModal.name2") }} </label>
                <input class="form-control" v-model="asset.name" />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> {{ $t("newAssets.model") }} </label>
                <input class="form-control" v-model="asset.model" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addAsset"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "NewAssets",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue", "add"],
  data() {
    return {
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      asset: {},
    };
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    addAsset() {
      this.loadAddBtn = true;
      http
        .fetch("/assets", this.asset, true)
        .then((data) => {
          this.loadAddBtn = false;
          this.myModal.hide();
          this.$emit("add", data);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
  components: {},
};
</script>
