<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              v-on:addNewRow="$refs.addAssetModal.showModal()"
              v-on:delete="deletePartners"
              :path="this.typePular"
              :methods="listOptions"
              :show-languages="false"
              :show-currencies="false"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              v-on:update:val="valUpdated"
              :show-pin="false"
              v-on:selected="(e) => (selectedItems = e)"
              :type="this.typePular"
              v-on:loadMore="loadMore"
              v-on:order="orderBy"
              :order="order"
              :fix-height="true"
              :key="tableKey"
            />
          </div>
        </div>
      </div>
    </div>
    <NewAssets ref="addAssetModal" v-on:add="addNewRow" />
  </div>
</template>

<script>
import DynamicTable from "@/components/lists/DynamicTable";
import TopBar from "@/components/lists/TopBar";
import { useStore } from "vuex";
import ws from "@/modules/ws";
import http from "@/modules/http";
import lineOptions from "@/modules/lineOptions";
import NewAssets from "@/components/modals/NewAssets";

export default {
  name: "Assets",
  components: { NewAssets, DynamicTable, TopBar },
  created() {},
  data() {
    return {
      store: useStore(),
      tableKey: 0,
      rows: [],
      filtersTimeOut: null,
      selectedLineOption: 0,
      lineOptions: [],
      selectAll: false,
      items: [],
      selectedItems: [],
      page: 1,
      newCustomRowValue: undefined,
      listOptions: [],
      order: {
        row: null,
        desc: true,
      },
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    path() {
      return this.$route.path;
    },
    sessionId() {
      return this.store.state.sessionId;
    },
    filters() {
      return this.store.state.filters?.assets;
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "assets");
    this.loadData();

    ws.init();

    ws.subscribe("add", "asset", (e) => {
      if (e.session !== this.sessionId) {
        this.items.push(e.object);
        this.tableKey++;
      }
    });

    ws.subscribe("modify", "asset", (e) => {
      let obj = this.items[this.items.findIndex((o) => o.id === e.objectId)];
      if (obj !== undefined) {
        if (e.session !== this.sessionId) {
          obj[e.rowKey] = e.value;
        }
      }
    });
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
  },
  methods: {
    orderBy(order) {
      this.items = undefined;
      this.page = 1;
      this.order = order;
      this.loadData();
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    addNewRow(row) {
      row.selected = this.selectAll;
      if (this.selectAll) {
        this.selectAllDiff++;
      }
      this.items.unshift(row);
      this.tableKey++;
    },
    loadData() {
      let url = `/assets?page=${this.page}&order[desc]=${
        this.order.desc ? 1 : 0
      }&order[row]=${this.order.row ? this.order.row : ""}`;

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&filter[${filter}][]=${value}`;
            }
          } else {
            url += `&filter[${filter}]=${this.filters[filter].value}`;
          }
        }
      }
      http.fetch(url).then((data) => {
        if (this.page === 1) {
          if (this.rows.length === 0) {
            let cs = data.rows.find((e) => e.row_key === "warehouse_id");
            cs.values = this.warehouses;

            cs = data.rows.find((e) => e.row_key === "asset_category_id");
            cs.values = data.categories;
            cs.createOption = (e) => {
              for (const obj of cs.values) {
                if (obj.name.toLowerCase() === e.toLowerCase()) {
                  return obj;
                }
              }
              this.newCustomRowValue = e;
              return { id: 0, name: e };
            };

            this.rows = lineOptions.init(data.rows, data.line_options);
            this.lineOptions = data.line_options;
            this.selectedLineOption = data.line_options[0]?.id;
          }
          this.items = data.data;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
      });
    },
    valUpdated(val, row, id, item) {
      if (row === "crmItem") {
        row = "accessed_to";
        val = val?.id;
      }
      let data = {};
      data[row] = val;

      if (val === 0 && this.newCustomRowValue === undefined) {
        return;
      }

      if (val === 0 && this.newCustomRowValue !== undefined) {
        data[row] = this.newCustomRowValue;
        data.add = 1;
        this.newCustomRowValue = undefined;
      }

      http.fetch(`/assets/${item.id}`, data, true, "PUT").then((respond) => {
        if (data.add) {
          let rowData = this.rows.filter((e) => e.key === row)[0];
          rowData.values.push({ id: respond.id, name: data[row] });
          item[row] = respond.id;
          this.newCustomRowValue = undefined;
        }
      });
    },
    deletePartners() {
      let data = [];
      for (const item of this.selectedItems) {
        data.push(item.id);
      }
      http.fetch(`/assets`, { rows: data }, true, "DELETE").then(() => {
        for (const item of this.selectedItems) {
          let i = this.items.indexOf(item);
          if (i > -1) {
            this.items.splice(i, 1);
          }
        }
        this.tableKey++;
      });
    },
  },
};
</script>
